import React, { useEffect, useState } from "react";
import { Checkbox } from "dms-lib";
import "./timeOfResponse.module.css";

import Slider from "../../../commons/slider/Slider";
import ResponseTimeInterval from "../../../../models/filters/ResponseTimeInterval";

interface TimeOfResponseProps {
  disable?: boolean;
  minValue: number;
  maxValue: number;
  onChangeCallback: (event) => void;
  disableCallback: (event) => void;
  selected: ResponseTimeInterval;
}

const TimeOfResponse = (props: TimeOfResponseProps): JSX.Element => {
  const {
    disable = false,
    onChangeCallback,
    selected,
    minValue,
    maxValue,
    disableCallback,
  } = props;
  const [isSelected, setIsSelected] = useState(!disable);
  const [timeOfResponseMin, setTimeOfResponseMin] = useState(null);
  const [timeOfResponseMax, setTimeOfResponseMax] = useState(null);

  useEffect(() => {
    onChangeCallback({ min: timeOfResponseMin, max: timeOfResponseMax });
  }, [timeOfResponseMin, timeOfResponseMax]);

  useEffect(() => {
    setIsSelected(!disable);
  }, [disable, selected]);

  const onChange = (e) => {
    setIsSelected(e);
    disableCallback(e);
    if (!e) {
      setTimeOfResponseMin(minValue);
      setTimeOfResponseMax(maxValue);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 time-response-align">
          <Checkbox
            label="Time of Response"
            key="timeOfResponse"
            checked={isSelected}
            onChange={(_, checked) => onChange(checked)}
          />
          <p
            style={{
              fontSize: "small",
              paddingTop: "5%",
            }}
          >
            (in minutes)
          </p>
        </div>
      </div>
      <div className="row">
        <div
          style={{ display: "flex", marginLeft: "var(--msi-ui-spacing-xxl)" }}
          className="col-12"
        >
          <Slider
            disabled={!isSelected}
            min={minValue}
            max={maxValue}
            selectedMin={isSelected ? timeOfResponseMin : minValue}
            selectedMax={isSelected ? timeOfResponseMax : maxValue}
            onChange={({ min, max }: { min: number; max: number }) => {
              setTimeOfResponseMin(min);
              setTimeOfResponseMax(max);
            }}
          />
        </div>
        <div className="col" />
      </div>
    </>
  );
};

export default TimeOfResponse;
