// General
import React, { ReactElement, useEffect, useState } from "react";
// Components
import { CardContent, CardTitle } from "@msi/cobalt-react/card";
// Styles
import "./collapsiblePanel.module.css";
// Store
import { BehaviorSubject } from "rxjs";

export interface CollapsiblePanelProps {
  icon?: ReactElement;
  content?: unknown;
  name?: string;
  hidden?: boolean | BehaviorSubject<boolean>;
  columnClass?: string;
  disabled?: boolean;
  scrollable?: boolean;
}

const CollapsiblePanel = (props: CollapsiblePanelProps): JSX.Element => {
  const {
    icon,
    content = <p>Empty Panel</p>,
    hidden = true,
    columnClass,
    name = "Collapsible Panel",
    disabled = false,
    scrollable = true,
  } = props;

  const [isHidden, setIsHidden] = useState(false);
  const [currentColumnClass, setCurrentColumnClass] = useState(
    isHidden ? null : columnClass
  );

  useEffect(() => {
    let isHiddenSubscription;
    if (hidden instanceof BehaviorSubject) {
      isHiddenSubscription = hidden.subscribe((value) => {
        if (value != null) {
          setIsHidden(value);
          setCurrentColumnClass(value ? null : columnClass);
        }
      });
    } else {
      setIsHidden(hidden);
      setCurrentColumnClass(hidden ? null : columnClass);
    }

    return function cleanup() {
      isHiddenSubscription?.unsubscribe();
    };
  }, [hidden]);

  const setCardHidden = () => {
    if (hidden instanceof BehaviorSubject) {
      hidden?.next(!hidden?.value);
    } else {
      setIsHidden(!isHidden);
    }
    if (currentColumnClass === null) {
      setCurrentColumnClass(columnClass);
    } else {
      setCurrentColumnClass(null);
    }
  };

  const getColumnClass = () => {
    return currentColumnClass ?? "";
  };

  const getDisabled = () => {
    return `${disabled ? "compass-disabled-div" : ""}`;
  };

  const getBtnTitleClass = () => {
    const classes = ["collapsible-panel-title"];
    if (!isDMS) {
      classes.push("collapsible-panel-title-rounded");
      if (!isHidden) classes.push("collapsible-panel-title-active");
    }
    return classes.join(" ");
  };

  return (
    <div
      className={`card collapsible-panel ${getColumnClass()} ${getDisabled()}`}
      style={{ overflow: scrollable ? "auto" : "hidden" }}
      data-cy="collapsible-panel-card"
    >
      <CardTitle>
        <i
          data-cy="card-icon"
          className={getBtnTitleClass()}
          aria-hidden="true"
          onClick={setCardHidden}
        >
          {icon}
        </i>
      </CardTitle>
      <div
        className={`collapsible-panel-content-div${isHidden ? "-hidden" : ""}`}
        data-cy={`collapsible-panel-content${isHidden ? "-hidden" : ""}`}
        id={name}
      >
        <CardContent>{content}</CardContent>
      </div>
    </div>
  );
};

export default CollapsiblePanel;
