import React, { useState } from "react";
import FilteringCriteria from "./filteringCriteria/FilteringCriteria";
import "./filterPanel.module.css";

const FilterPanel = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false);

  const filtersLoadedCallback = (isLoaded) => {
    setLoaded(isLoaded);
  };

  return (
    <>
      {!loaded && (
        <div className="timeline-no-incident" data-cr="filter-no-incident">
          <span>Please refresh the page</span>
          <span>or check your internet connection.</span>
        </div>
      )}
      <div className="filter-panel" hidden={!loaded}>
        <FilteringCriteria callback={filtersLoadedCallback} />
      </div>
    </>
  );
};

export default FilterPanel;
